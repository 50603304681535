<script lang="ts" setup>
import { IonList } from "@ionic/vue";
import { computed } from "vue";
import FileItem from "@/components/business/FileItem.vue";
import { FabriqFile } from "@/types";

interface Props {
  files: Array<FabriqFile>;
  readonly?: boolean;
  viewer: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  readonly: false,
});

const ordered = computed(() => {
  const files = props.files || [];
  files.sort((a: FabriqFile, b: FabriqFile) =>
    String(a.created_at || "").localeCompare(String(b.created_at || ""))
  );
  return files;
});
</script>

<template>
  <ion-list class="file-list ion-padding-vertical" lines="none">
    <file-item
      v-for="file of ordered"
      :key="file.uuid ?? file.id"
      :file="file"
      :viewer="viewer"
      :readonly="readonly"
      @delete="() => $emit('delete', file)"
    />
  </ion-list>
</template>

<style scoped>
.add-file .font-icon {
  margin: auto;
}

ion-item::part(native) {
  padding-left: var(--ion-padding);
  --inner-padding-end: 0;
}
</style>
