import mixpanelSdk from "mixpanel-browser";

import config from "@/config";
import { useFabriqStore } from "@/store/fabriq";

class MixpanelTracker {
  #sdk: any;

  checkInitialisation() {
    if (this.#sdk) return true;

    const fabriqStore = useFabriqStore();
    const subdomain = fabriqStore.subdomain;
    if (!subdomain) return false;

    this.#sdk = mixpanelSdk;
    this.#sdk.init(config.mixpanelToken, {
      cross_site_cookie: true,
      api_host: `${subdomain}${config.mixpanelPath}`,
    });

    return true;
  }

  track(...args: any) {
    if (!this.checkInitialisation()) return;
    if (!config.mixpanelToken?.length)
      return console.warn("No track on non production", ...args);
    if (this.#sdk && this.#sdk.track) {
      this.#sdk.track(...args);
      const [message, ...rest] = args;
      if (!message.startsWith("mb |"))
        this.#sdk.track(`mb | ${message}`, ...rest);
    }
  }

  setUser(user: any) {
    if (!this.checkInitialisation()) return;
    if (!config.mixpanelToken?.length)
      return console.warn("No mixpanel on non production");
    if (this.#sdk && this.#sdk.register) {
      this.#sdk.register({
        email: user.email,
      });
      this.#sdk.identify(`${user.id}`);
      this.#sdk.people.set({
        "First name": user.first_name,
        "Last name": user.last_name,
        Email: user.email,
      });
    }
  }
}

const mixpanelTracker = new MixpanelTracker();

export default mixpanelTracker;
